<template>
  <contentCard>
    <div class="no-auth-img"></div>
    <div class="text">
      未模范履行业主义务的居民，暂无订餐权限。请到物业中心缴纳物业费，履行业主义务，如有疑问请联系社区工作人员：0574-55711314
    </div>
  </contentCard>
</template>

<script>
import contentCard from '../components/contentCard.vue';

export default {
  name: 'ytjEldCanMealsList',
  components: {
    contentCard
  }
};
</script>

<style lang="less" scoped>
/deep/ .content-body {
  padding: 0px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .no-auth-img {
    box-sizing: border-box;
    width: 330px;
    height: 270px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-image: url('./img/no_auth.png');
  }
  .text {
    font-size: 24px;
    line-height: 33px;
    color: #1874fd;
    margin-top: 20px;
    text-align: center;
  }
}
</style>
